import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import set from 'lodash/set';

import { ADD_TO_CART, GO_TO_CHECKOUT } from '../../../../../../components/Ecommerce/Ecwid/Custom/constants';
import delay from '../../../../../../helper/delay';
import { SHARING_BUTTONS_WIDGET_SELECTOR } from '../../../../../constants';
import ecommerce from '../../../../../ecommerce/ecwid/custom';
import router from '../../../../../ecommerce/ecwid/custom/router';
import dom from '../../../../../wrapper/DomWrapper';
import { LIGHT_BOX_SELECTOR } from '../../../../Gallery/constants';
import ItemRenderer from '../../../../ItemsView/renderer';
import lazyLoad from '../../../../LazyLoad';
import LightBox from '../../../../Lightbox';
import { Parallax } from '../../../../Parallax';
import extendShemaOrg from '../../../../SchemaOrg';
import SharingButtons from '../../../../SharingButtons';
import {
  PRODUCT_BUY_BUTTON_ID,
  PRODUCT_OPTIONS_ID,
  PRODUCT_PREVIOUS_PRICE_ID,
  PRODUCT_QUANTITY_IN_CART_ID,
  PRODUCT_QUANTITY_INPUT_ID,
  PRODUCT_REGULAR_PRICE_ID,
  PRODUCT_SAVED_PERCENT_ID,
} from '../../custom/Product/constants';

import {
  CLASS_NAMES,
  LIGHT_BOX_OPTIONS,
  REST_IMAGES_MAX_SIZE,
} from './constants';
import ProductOptions from './ProductOptions';
import { checkGalleryPagination } from './utils';

class ProductPage {
  constructor() {
    this.state = {
      product: {},
      order: {
        quantityToAdd: 1,
        options: {},
      },
      isAdding: false,
    };
    this.productId = null;
    this.defaultProductId = null;
    this.elContainer = null;
    this.settings = null;
    this.renderer = null;
    this.lightBox = null;
    this.sharingButtons = null;
    this.galleryLightBoxData = null;
  }

  init = async (data) => {
    const elPage = dom.getElement('.js-widget[data-widget="dashProductPage"]');

    if (!elPage || !data?.id) return;

    try {
      const { settings: settingsStr } = elPage.dataset;
      const settings = JSON.parse(settingsStr);

      this.productId = data?.id;
      this.defaultProductId = data?.defaultId;
      this.elContainer = dom.getElement(`#${settings?.containerId}`);
      this.settings = settings;

      if (!this.elContainer) return;

      await this.fetchProduct(this.productId);

      if (!this.productWithOptions) await this.handleQuantityInCartChange();

      await this.render();

      if (!this.productWithOptions) await this.updateCart();

      this.updatePrice();
      this.initQuantityInCart();

      if (this.productWithOptions) this.initOptions();

      this.initBuyButton();
      this.initSchemaOrg();
      this.initSharingButtons();

      const elTitle = dom.getElement('title');

      elTitle.innerHTML = this.state.product.name;

      Parallax.forceUpdate();
    } catch (error) {
      console.error(error);
    }
  };

  initSharingButtons = () => {
    this.sharingButtons = new SharingButtons(SHARING_BUTTONS_WIDGET_SELECTOR);

    this.sharingButtons.init();
  };

  initSchemaOrg = () => {
    const {
      name, price, compareToPrice, url, sku, description, condition, images, inStock, quantity,
    } = this.state.product;

    extendShemaOrg('Product', ['@context'], 'https://schema.org/');
    extendShemaOrg('Product', ['name'], name);
    extendShemaOrg('Product', ['image'], images.map((image) => image.imageOriginalUrl));
    extendShemaOrg('Product', ['sku'], sku);

    if (description) {
      const descriptionWithoutTags = description.replace(/<\/?[^>]+(>|$)/g, '');

      extendShemaOrg('Product', ['description'], descriptionWithoutTags);
    }

    extendShemaOrg('Product', ['offers', '@type'], 'Offer');
    extendShemaOrg('Product', ['offers', 'price'], price.toString());
    extendShemaOrg('Product', ['offers', 'url'], url);
    extendShemaOrg(
      'Product',
      ['offers', 'availability'],
      inStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'
    );
    extendShemaOrg('Product', ['offers', 'itemCondition'], condition !== 'NEW' ? 'https://schema.org/UsedCondition' : 'https://schema.org/NewCondition');

    if (compareToPrice) {
      extendShemaOrg('Product', ['offers', 'offers', '@type'], 'AggregateOffer');
      extendShemaOrg('Product', ['offers', 'offers', 'highPrice'], compareToPrice.toString());
      extendShemaOrg('Product', ['offers', 'offers', 'lowPrice'], price.toString());

      if (quantity) extendShemaOrg('Product', ['offers', 'offers', 'offerCount'], quantity.toString());
    }
  };

  updateCart = async ({ isReset = false } = {}) => {
    this.updateBuyButton();
    await this.updateQuantityInput(isReset);
    await this.updateQuantityInCart();
  };

  initBuyButton = () => {
    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);
    const {
      options,
    } = this.state.product;

    const defaultProduct = get(options, '[0].defaultProduct');

    if (defaultProduct) dom.addClass(elBuyButton, CLASS_NAMES.showTooltip);

    dom.on(elBuyButton, 'click', this.handleButtonAction);
  };

  handleButtonAction = async () => {
    const { buttonAction } = this.settings;

    switch (buttonAction) {
      case ADD_TO_CART:
        await this.addToCart();

        break;

      case GO_TO_CHECKOUT:
        await this.addToCart(() => router.goToCartPage(true));

        break;
      default:
        console.error('wrong buttonAction');

        break;
    }
  };

  updateBuyButton = () => {
    const productQuantity = this.getProductQuantity();
    const isDisabled = !productQuantity;
    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);

    if (!elBuyButton) return;

    elBuyButton.disabled = isDisabled;
  };

  updateBuyButtonTooltip = () => {
    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);

    if (!elBuyButton) return;

    if (this.canBuy()) {
      dom.removeClass(elBuyButton, CLASS_NAMES.showTooltip);
    } else {
      dom.addClass(elBuyButton, CLASS_NAMES.showTooltip);
    }
  };

  initOptions = () => {
    const {
      options,
    } = this.state.product;

    const elOptionsContainer = dom.getElement(`#${PRODUCT_OPTIONS_ID}`);

    (options || []).forEach(({
      variants,
      products,
      defaultProduct,
    }) => {
      variants?.forEach(({
        name,
        choices,
      }) => {
        const productOption = new ProductOptions(
          name,
          choices,
          (price) => ecommerce.provider.formatPrice(price)
        );

        productOption.connect(
          elOptionsContainer,
          this.handleChangeOption(products, variants, defaultProduct)
        );

        if (this.checkOutOfStock()) this.hideProductOptions();
      });
    });
  };

  handleChangeOption = (products, variants, defaultProduct) => async (e) => {
    const { name, value } = e.target;

    this.setOption(name, value);
    variants.reduce((acc, variant) => {
      if (!acc) this.removeOption(variant.name);

      if (variant.name === name) return false;

      return acc;
    }, true);

    this.updatePrice(products, variants, defaultProduct);

    if (this.productWithOptions) await this.correctProductOptionQuantity();

    await this.updateCart({ isReset: true });
  };

  getProductId = () => this.defaultProductId || this.productId;

  getProductOptionsData = () => {
    const productId = this.getProductId();
    const products = get(this, 'state.product.options[0].products', []);
    const index = products.findIndex((product) => product.id === productId);

    return {
      product: products[index],
      path: `state.product.options[0].products[${index}]`,
    };
  };

  getProductQuantity = () => {
    if (!this.productWithOptions) return this.state.product.quantity;

    const { product } = this.getProductOptionsData();

    return product?.quantity || 0;
  };

  updateProductQuantity = (quantity) => {
    const prepareQuantity = quantity < 0 ? 0 : quantity;

    if (!this.productWithOptions) {
      this.updateProduct({
        quantity: prepareQuantity,
      });

      return;
    }

    const { path } = this.getProductOptionsData();

    set(this, `${path}.quantity`, prepareQuantity);
  };

  correctProductOptionQuantity = async () => {
    const { path, product } = this.getProductOptionsData();

    if (product?.withCorrectedQuantity) return;

    const countInCart = await this.getQuantityInCart();
    const productQuantity = this.getProductQuantity();
    const availableQuantity = productQuantity - countInCart;

    this.updateProductQuantity(availableQuantity);
    set(this, `${path}.withCorrectedQuantity`, true);
  };

  getQuantityInCart = async () => {
    let countInCart;

    try {
      countInCart = await ecommerce.provider.cart.itemGetQuantity(this.getProductId());
    } catch {
      countInCart = 0;
    }

    return countInCart;
  };

  setOption = (optionName, value) => {
    if (isNil(value)) return;

    this.state.order.options[optionName] = value;
  };

  removeOption = (optionName) => {
    delete this.state.order.options[optionName];
  };

  canBuy = () => {
    if (!this.productWithOptions) return true;

    const allSetOptions = Object.keys(this.state.order.options);
    const allOptions = this.state.product.options[0]?.variants;

    return allOptions.length === allSetOptions.length;
  };

  updatePrice = (products, variants, defaultProduct) => {
    const elRegularPrice = dom.getElement(`#${PRODUCT_REGULAR_PRICE_ID}`);
    const elPreviousPrice = dom.getElement(`#${PRODUCT_PREVIOUS_PRICE_ID}`);
    const elSavedPercent = dom.getElement(`#${PRODUCT_SAVED_PERCENT_ID}`);

    if (!isEmpty(variants)) {
      const allSetOptions = Object.keys(this.state.order.options);

      if (isEmpty(allSetOptions)) {
        this.defaultProductId = defaultProduct.id;

        this.updateProduct({
          price: defaultProduct.price,
          compareToPrice: defaultProduct.compareToPrice,
        });
      } else {
        const variantIds = allSetOptions.reduce((acc, option) => {
          const value = this.state.order.options[option];
          const findOption = variants.find((variant) => variant.name === option);
          const findVariant = findOption.choices.find((choice) => choice.text === value);

          return [
            ...acc,
            findVariant.id,
          ];
        }, []);

        const enabledProducts = products
          .filter((el) => el.quantity !== 0)
          .filter((el) => variantIds.reduce((acc, variantId) => {
            if (!acc) return acc;

            return Object.values(el.variations).some((variation) => variation.choiceId === variantId);
          }, true));

        const updatedVariants = variants
          .map((variant) => {
            const {
              choices,
            } = variant;

            return {
              ...variant,
              choices: choices.map((choice) => ({
                ...choice,
                disabled: !enabledProducts?.some(
                  (product) => Object.values(product.variations).some((variation) => variation.choiceId === choice.id)
                ),
              })),
            };
          })
          .filter((variant) => !allSetOptions.includes(variant.name));

        updatedVariants.forEach((variant) => {
          const { name, choices } = variant;

          choices.forEach((choice) => {
            const {
              disabled,
              text,
            } = choice;

            const elChoice = dom.getElement(`[name="${name}"][value="${text}"]`);

            elChoice.disabled = disabled;

            if (disabled) {
              dom.addClass(elChoice.parentElement, CLASS_NAMES.showTooltip);
              elChoice.checked = false;
            } else {
              dom.removeClass(elChoice.parentElement, CLASS_NAMES.showTooltip);
            }
          });
        });

        const lowPriceProduct = enabledProducts.length > 0
          ? enabledProducts.sort((a, b) => a.price - b.price)[0]
          : enabledProducts[0];

        this.defaultProductId = lowPriceProduct.id;

        this.updateProduct({
          price: lowPriceProduct.price,
          compareToPrice: lowPriceProduct.compareToPrice,
        });
        this.updateBuyButtonTooltip();
      }
    } else {
      const defaultProductId = get(this.state.product, 'options[0].defaultProduct.id');

      if (defaultProductId) this.defaultProductId = defaultProductId;
    }

    const price = ecommerce.provider.getPrice(
      this.state.product.price,
      1
    );

    const previousPrice = ecommerce.provider.getPreviousPrice(
      this.state.product.price,
      this.state.product.compareToPrice,
      1
    );

    const savedPercent = ecommerce.provider.getSavedPercent(price, previousPrice);

    dom.addText(elRegularPrice, `${ecommerce.provider.formatPrice(price)}`);
    dom.addText(
      elPreviousPrice,
      previousPrice ? `${ecommerce.provider.formatPrice(previousPrice)}` : ''
    );
    dom.addText(elSavedPercent, savedPercent ? ` Save ${savedPercent}%` : '');
  };

  checkOutOfStock = () => this.state.product.isOutOfStock();

  // eslint-disable-next-line class-methods-use-this
  hideProductOptions = () => {
    const container = dom.getElement(`#${PRODUCT_OPTIONS_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  initLightBox = () => {
    if (this.lightBox) return;

    this.lightBox = new LightBox(LIGHT_BOX_SELECTOR);
  };

  initGallery = () => {
    if (!this.state.product?.withGallery || this.state.product?.images?.length === 0) {
      dom.addClass(this.elContainer, CLASS_NAMES.noGallery);

      return;
    }

    this.initLightBox();
    this.initRestGallery();
  };

  initRestGallery = () => {
    const { showMainImage } = this.settings;
    const { product: { images = [] } } = this.state;

    const mapGalleryImages = Object.keys(images)
      .slice(1)
      .reverse()
      .map((key, index) => ({
        src: images[key].imageUrl,
        id: `product-gallery-item-${index + 1}`, // original image has 0 index, rest images start from 1 index
        ...LIGHT_BOX_OPTIONS,
      }));

    const withRestImages = !showMainImage || !isEmpty(mapGalleryImages);

    this.updateProduct({
      originalImage: { src: images[0]?.imageUrl },
      withRestImages,
    });
    this.initGalleryLightBox(mapGalleryImages);

    if (!withRestImages) return;

    this.initRestAdditionalGallery(mapGalleryImages);
  };

  initRestAdditionalGallery = (mapGalleryImages = {}) => {
    const { showMainImage } = this.settings;

    const mapGalleryImagesSize = mapGalleryImages.length;
    const maxGalleryImages = Math.min(mapGalleryImagesSize, REST_IMAGES_MAX_SIZE);
    const restImagesWrapClassName = `${CLASS_NAMES.restImagesWrap}_${showMainImage ? maxGalleryImages : maxGalleryImages + 1}`;

    const paginationSize = mapGalleryImagesSize - REST_IMAGES_MAX_SIZE;

    const fnGalleryPagination = (index) => checkGalleryPagination(index, paginationSize);

    this.updateProduct({
      mapGalleryImages,
      restImagesWrapClassName,
      galleryPaginationCount: paginationSize + 1,
      checkGalleryPagination: fnGalleryPagination,
      checkGalleryHiddenItem: (index) => index >= REST_IMAGES_MAX_SIZE && !fnGalleryPagination(index),
    });
  };

  initGalleryLightBox = (mapGalleryImages = {}) => {
    const { product: { originalImage = {} } } = this.state;
    const galleryLightBoxImages = [{ ...originalImage, ...LIGHT_BOX_OPTIONS }, ...mapGalleryImages];

    this.galleryLightBoxData = galleryLightBoxImages.reduce((acc, image, index) => ({
      ...acc,
      [`product-gallery-item-${index}`]: image,
    }), {});
  };

  connectGalleryLightBox = () => {
    const { hash } = this.settings;
    const galleryHash = `product-gallery-${hash}`;
    const galleryList = { [galleryHash]: this.galleryLightBoxData };

    const {
      navButtonsAttributes,
      modalsParams,
    } = this.lightBox.getLightBoxAttributes();

    this.lightBox.galleryModalData[galleryHash] = this.galleryLightBoxData;

    this.lightBox.addLightBoxToGalleryPhotos(
      galleryList,
      navButtonsAttributes,
      modalsParams
    );
  };

  setState = (nexState = {}) => {
    this.state = {
      ...this.state,
      ...nexState,
    };

    return this.state;
  };

  fetchProduct = async (productId) => {
    console.warn('product id', productId);

    try {
      dom.addClass(this.elContainer?.parentNode, CLASS_NAMES.spinner);
      const productData = await ecommerce.provider.getProduct(productId, true);

      this.updateProduct({
        ...productData,
      });

      this.productWithOptions = this.state.product.checkWithOptions();
    } catch (error) {
      console.error(error);
    } finally {
      dom.removeClass(this.elContainer?.parentNode, CLASS_NAMES.spinner);
    }
  };

  updateProduct = (data = {}) => {
    this.setState({
      product: {
        ...this.state.product,
        ...data,
      },
    });
  };

  handleQuantityInCartChange = async () => {
    const productQuantity = this.getProductQuantity();
    const countInCart = await this.getQuantityInCart();
    const availableQuantity = productQuantity - countInCart;

    this.updateProductQuantity(availableQuantity);
  };

  addToCart = async (cb) => {
    if (this.state.isAdding || !this.canBuy()) return;

    if (this.productWithOptions) await this.correctProductOptionQuantity();

    const productQuantity = this.getProductQuantity();

    if (!productQuantity) {
      await this.updateCart();

      return;
    }

    const quantityAddToCart = this.state.order.quantityToAdd;
    const prepareQuantityAddToCart = Math.min(quantityAddToCart, productQuantity);
    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);

    if (elBuyButton) dom.addClass(elBuyButton, CLASS_NAMES.buttonSpinner);

    this.state.isAdding = true;
    dom.addClass(this.elContainer, CLASS_NAMES.isAdding);

    try {
      await ecommerce.provider.cart.itemAdd(this.getProductId(), prepareQuantityAddToCart, {}, async () => {
        this.updateProductQuantity(productQuantity - prepareQuantityAddToCart);
        await this.animateBuyButton(elBuyButton);
        await this.updateCart();
        this.state.isAdding = false;
        dom.removeClass(this.elContainer, CLASS_NAMES.isAdding);
        if (isFunction(cb)) cb();
      });
    } catch {
      dom.removeClass(elBuyButton, CLASS_NAMES.buttonSpinner);
      dom.removeClass(this.elContainer, CLASS_NAMES.isAdding);
      this.state.isAdding = false;
    }
  };

  initQuantityInCart = () => {
    const elQuantityInput = dom.getElement(`#${PRODUCT_QUANTITY_INPUT_ID}`);

    dom.on(elQuantityInput, 'change', this.handleChangeQuantity);
  };

  updateQuantityInput = async (isReset) => {
    const productQuantity = this.getProductQuantity();
    const elQuantityInput = dom.getElement(`#${PRODUCT_QUANTITY_INPUT_ID}`);

    if (!elQuantityInput) return;

    if (productQuantity) {
      elQuantityInput.min = 1;

      if (isReset) {
        elQuantityInput.value = 1;
        this.state.order.quantityToAdd = 1;
      }

      elQuantityInput.max = productQuantity;
      elQuantityInput.disabled = false;

      return;
    }

    const countInCart = await this.getQuantityInCart();

    elQuantityInput.min = countInCart;
    elQuantityInput.value = countInCart;
    elQuantityInput.max = countInCart;
    elQuantityInput.disabled = true;
  };

  getQuantityInCartText = async () => {
    const { getTranslate = () => {}, isDigital } = this.state.product;

    if (isDigital) return getTranslate('se.wf.ecom_digital_product_in_the_cart');

    const countInCart = await this.getQuantityInCart();
    const prefix = countInCart > 1 ? 'se.wf.ecom_product_in_the_cart_items' : 'se.wf.ecom_product_in_the_cart_item';

    return `${countInCart} ${getTranslate(prefix)} ${getTranslate('se.wf.ecom_product_in_the_cart_no_more')}`;
  };

  updateQuantityInCart = async () => {
    const productQuantity = this.getProductQuantity();
    const elQuantityInCart = dom.getElement(`#${PRODUCT_QUANTITY_IN_CART_ID}`);

    if (!elQuantityInCart) return;
    if (productQuantity) {
      dom.addClass(elQuantityInCart, CLASS_NAMES.hidden);
      dom.addText(elQuantityInCart, '');

      return;
    }

    const text = await this.getQuantityInCartText();

    dom.addText(elQuantityInCart, text);
    dom.removeClass(elQuantityInCart, CLASS_NAMES.hidden);
  };

  handleChangeQuantity = (e) => {
    const { value } = e.target;
    const parseIntValue = Number.parseInt(value, 10);
    const mathAbsValue = Math.abs(value);

    if (parseIntValue < 0) {
      e.target.value = mathAbsValue;
      this.state.order.quantityToAdd = mathAbsValue;
    } else if (parseIntValue === 0) {
      e.target.value = 1;
      this.state.order.quantityToAdd = 1;
    } else {
      this.state.order.quantityToAdd = mathAbsValue;
    }
  };

  animateBuyButton = async (elBuyButton) => {
    if (!elBuyButton) return;

    dom.removeClass(elBuyButton, CLASS_NAMES.buttonSpinner);
    dom.addClass(elBuyButton, CLASS_NAMES.buttonActive);

    await delay(2000);

    dom.removeClass(elBuyButton, CLASS_NAMES.buttonActive);
  };

  render = () => {
    const { templateId } = this.settings;

    this.initGallery();

    this.renderer = new ItemRenderer(dom.getElement(`#${templateId}`), {
      imports: { forEach, isNil },
    });

    this.elContainer.innerHTML = this.renderer.render({ product: this.state.product });

    if (this.galleryLightBoxData) this.connectGalleryLightBox();

    lazyLoad();
  };
}

export default ProductPage;
